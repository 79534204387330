import React, { Component, useRef } from 'react';
import Header from './Header';
import Main from './Main';
import SignUp from './SignUp';
import AboutUs from './AboutUs';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.homeRef = React.createRef();
    this.signUpRef = React.createRef();
    this.aboutUsRef = React.createRef();

  }

  componentDidMount = async () => {
    window.addEventListener('scroll', this.handleScroll);
    // this.setState({loading: true})
    // TODO
    // this.setState({loading: false})
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const header = document.querySelector('.header');
    const scroll = window.scrollY;
    const opacity = Math.min(1, scroll / 50);

    header.style.backgroundColor = `rgba(0, 0, 0, ${opacity})`;
  };

  scrollToSection = (ref) => {
      ref.current.scrollIntoView({ behavior: 'smooth'});
  };

  render() {
    return (
      <div>
        <Header 
            onHomeClick={() => this.scrollToSection(this.homeRef)}
            onSignUpClick={() => this.scrollToSection(this.signUpRef)}
            onAboutUsClick={() => this.scrollToSection(this.aboutUsRef)}
        />
        <Main forwardedRef={this.homeRef}/>
        <SignUp forwardedRef={this.signUpRef}/>
        <AboutUs  forwardedRef={this.aboutUsRef}/>
      </div>
    );
  }
}

export default Home;
