import React from 'react';
import matt from './matt.png';
import tommy from './tommy.png';
import logoImage from './gradient-logo-black.png';


const AboutUs = (props) => {
  const mattBio = "Matt is a Bay Area native who recently graduated from UC Berkeley, where he was a right-handed pitcher on the baseball team for 2 years. Prior to his time at Cal, Matt was a multi-sport athlete at San Marin High School. Despite his success on the field, he gained no attention from D1 coaches and elected to play junior college baseball at the College of Marin. Matt knew he had to make some changes to get to the next level and went all in on developing himself as an athlete to get there. As a result, he thrived on the mound at College of Marin where he garnered a plethora of awards including 2021 NorCal Pitcher of the Year and PAD First Team All-American. He credits taking a holistic approach toward his training as well as surrounding himself with the right people for his rapid improvement that landed him a spot in the Pac12. Matt is currently training to play professional baseball and believes in guiding the next generation using the same principles that continue to help him succeed in his own athletic journey."
  const tommyBio = "Tommy grew up in Southern California where he played football and baseball at San Marino High School. He didn’t make the switch from middle infield and outfield to pitching until his senior year, which ultimately prevented him from getting looks during the standard recruiting timeline. With a priority on academics, Tommy elected to go to MIT where he majored in Computer Science and saw his fastball velocity continue to rise as a pitcher on the D3 baseball team. COVID-19 canceled his junior and senior college seasons, which he took as an opportunity to continue his development and self-train his way to a graduate year of D1 baseball at Cal. Tommy has collected software engineering experience at Amazon and Facebook, and now develops a portfolio of technical products including tools to help pitchers maximize their potential. Despite his focus on technology, he believes in prioritizing athleticism as the core foundation before any data-driven specialization. "
  return (
    <div ref={props.forwardedRef} className="about-us">
        <img src={logoImage} className="logo-image" alt="Logo" />
        <div className="people-container">
            <div className="person-column" style={{marginBottom: "-50px"}}>
                <img src={matt} alt="Person 1" />
                <h3 className="name">Matt Lozovoy</h3>
                <p className="bio">{mattBio}</p>
            </div>
            <div className="person-column">
                <img src={tommy} alt="Person 2" />
                <h3 className="name">Tommy Hannan</h3>
                <p className="bio">{tommyBio}</p>
            </div>
        </div>
    </div>
  );
};

export default AboutUs;
