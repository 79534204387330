import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';


const SignUp = (props) => {

  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [emailValue, setEmailValue] = useState('');

  const sendEmail = (e) => {
    if (submitted) {return}

    setSubmitted(true);
    setEmailValue('');

    console.log("Trying to send...");

    e.preventDefault();

    emailjs
      .sendForm('service_pjdbhtm', 'template_j0up31e', form.current, {
        publicKey: 'bbk9oeHRzg0_HnlcV',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      setTimeout(() => {
        setSubmitted(false);
      }, 3000); 
  };

  const buttonText = submitted ? 'Submitted' : 'Get Started';
  const buttonClass = submitted ? 'get-started-button submitted' : 'get-started-button';

  const handleChange = (e) => {
    setEmailValue(e.target.value);
  };

  const text = "Here at Gradient, our mission is to help athletes set and achieve their goals in an efficient manner. Our guiding principle is acknowledging the fact that every athlete is different and must follow a unique developmental journey to get where they want to be. While other platforms may claim to have a magic drill or product to fix your problems, what works for one player may not work for another. For this reason, we use a holistic approach to identify the areas that should be prioritized to maximize overall improvement and treat data and technology as a development tool rather than the overall solution. The best opportunity for us to help you begins with a simple conversation."
  
  return (
    <div ref={props.forwardedRef} className="sign-up">
        <h1 className="definition"><span style={{color: 'black', fontSize: '1.2em', textDecoration: 'underline'}}>gra·di·ent</span> <span style={{fontColor: 'black', fontStyle: 'italic'}}>(noun):</span> the direction and rate of fastest increase in a multi-variable equation.</h1>
        <p className="philosophy">Drop your email below to get started with a free video analysis:</p>
        <form ref={form} onSubmit={sendEmail} className="signup-form">
          <input
            type="email"
            name="user_email"
            placeholder="Enter your email address"
            className="email-input"
            value={emailValue}
            onChange={handleChange}
            required
          />
          <button type="submit" className={buttonClass}>{buttonText}</button>
        </form>
      <p className="philosophy">{text}</p>
    </div>
  );
};

export default SignUp;
