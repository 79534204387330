import React from 'react';
import mainImage from './gradient-main.png';
import contentImage from './gradient-main-content.png'

const Main = (props) => {
  return (
    <div ref={props.forwardedRef} className="main">
        <img src={mainImage} alt="Main" className="hero-background"/>
        <img
            src={contentImage}
            alt="Content"
            className="main-content"
        />
    </div>
  );
};

export default Main;
