import React from 'react';
import titleImage from './gradient-title.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onHomeClick, onSignUpClick, onAboutUsClick } = this.props;

    return (
      <header className="header">
        {/* Left side - Logo */}
        <div >
          <img src={titleImage} alt="Logo" className='header-logo' onClick={onHomeClick}/>
        </div>

        {/* Right side - Buttons */}
        <div>
          <button className="section-button" onClick={onSignUpClick}>
            Sign Up
          </button>
          <button className="section-button" onClick={onAboutUsClick}>
            About Us
          </button>
        </div>
      </header>
    );
  }
}

export default Header;
